
import Vue from "vue";

export const EventBus = new Vue({
  data: {
    processes: 0,
    ended: 0,
  },
  methods: {
    isAuthenticated() {
      return this.$msal.isAuthenticated();
    },
    signIn() {
      console.log("signIn");
      if (!this.$msal.isAuthenticated()) {
        this.$msal.signIn();
      }
    },
    signOut() {
      console.log("signOut");
      if (this.$msal.isAuthenticated()) {
        this.$msal.signOut();
      }
    },
    startLoad() {
      if (this.processes == 0) this.$emit("loadStarted");
      this.processes += 1;
      this.$emit("increment", this.processes);
    },
    endLoad() {
      this.ended += 1;
      this.$emit("decrement", this.ended);
      if (this.processes <= this.ended) {
        this.processes = 0;
        this.ended = 0;
      }
      if (this.processes == 0) this.$emit("loadFinished");
    },
  },
});

export function isValidJwt() {
  const jwt = localStorage.jwt_token;
  if (!jwt || jwt.split(".").length < 3) {
    return false;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  const now = new Date();
  return now < exp;
}

export function expirationTime(jwt) {
  if (!jwt || jwt.split(".").length < 3) {
    return 0;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  const now = new Date();
  return Math.abs(now - exp);
}

export function expiresAt(jwt) {
  if (!jwt || jwt.split(".").length < 3) {
    return 0;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  return exp;
}

export function generateUniqueId() {
  return (
    Math.random()
      .toString(36)
      .substring(2) + Date.now().toString(36)
  );
}

export function areEqualsObjects(object1, object2) {
  Object.entries(object1).forEach((key, value) => {
    if (Object.prototype.hasOwnProperty.call(object2, key)) return false;
    if (object2[key] != value) return false;
  });
  return JSON.stringify(object1) == JSON.stringify(object2);
}

export function toast(message) {
  Vue.swal
    .mixin({
      toast: true,
      position: "bottom-start",
      showConfirmButton: false,
      timer: 3000,
      title: message,
    })
    .fire();
}

export function customToast(message, position, timer) {
  Vue.swal
    .mixin({
      toast: true,
      position: position,
      showConfirmButton: false,
      timer: timer,
      title: message,
    })
    .fire();
}

export function round(number) {
  return +(Math.round(Number(number) + "e+2") + "e-2");
}

export function base64UrlToBytes(base64Url) {
  const padding = '='.repeat((4 - base64Url.length % 4) % 4);
  const base64 = (base64Url + padding).replace(/-/g, '+').replace(/_/g, '/');
  const bytes = window.atob(base64).split('').map(char => char.charCodeAt(0));
  return new Uint8Array(bytes);
}

export const backend_usecured_routes = [
  '/token/',
  '/token/refresh/',
  '/login/',
  '/authentication/send_reset_code/',
  '/authentication/reset-password/',
  '/common/contact-data/',
  '/health_check',
];

export const buildUrlParams = (payload) => {
  let params = ['all_data=True'];
  Object.keys(payload).forEach(key => {
    if (Array.isArray(payload[key])) {
      if (payload[key].length > 0) {
        params.push(`${key}=${payload[key].join(",")}`);
      }
    } else if (payload[key]) {
      params.push(`${key}=${payload[key]}`);
    }
  });
  return params.length === 1 ? null : params.join('&');
};