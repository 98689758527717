import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    new_test_segments: [],
    new_test_segments_url: '/evaluations2/test-segment',
    new_redaction_questions: [],
    new_redaction_questions_url: '/evaluations2/redaction-question',
    // Choices redactionQuestionMaxAnswerLength
    redaction_question_length_answers: [],
    redaction_question_length_answers_url: '/evaluations2/redaction-question-max-answer-length-type-choices',
    // Choices multipleChoiceQuestion
    new_multiple_choice_questions: [],
    new_multiple_choice_questions_url: '/evaluations2/multiple-choice-question',
    new_multiple_choice_question_options: [],
    new_multiple_choice_question_options_url: '/evaluations2/multiple-choice-question-option',
    // Choices multipleChoiceQuestionSubtype
    new_multiple_choice_question_subtype: [],
    new_multiple_choice_question_subtype_url: '/evaluations2/multiple-choice-question-subtype-choices',
    // Pregunta de rellenado FillingQuestion
    new_filling_questions: [],
    new_filling_questions_url: '/evaluations2/filling-question',
    new_filling_question_options: [],
    new_filling_question_options_url: '/evaluations2/filling-question-blank',
    // Pregunta de rellenado MatchingQuestion
    new_matching_questions: [],
    new_matching_questions_url: '/evaluations2/matching-question',
    new_matching_question_options: [],
    new_matching_question_options_url: '/evaluations2/matching-question-unit',
    new_matching_question_scores: [],
    new_matching_question_scores_url: '/evaluations2/matching-question-unit-middle-relation',
    // Sequential Question
    new_sequential_questions: [],
    new_sequential_questions_url: '/evaluations2/sequential-question',
    // Choice FillingQuestionSubTypes
    new_filling_question_sub_types: [],
    new_filling_question_sub_types_url: '/evaluations2/filling-question-subtype-choices',
    // Choices MultipleQuestionScoreType
    multiple_question_score_types: [],
    multiple_question_score_types_url: '/evaluations2/multiple-choice-question-automated-score-type-choices',
}
const getters = {
    [names.NEW_TEST_SEGMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.new_test_segments.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_REDACTION_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_redaction_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.REDACTION_QUESTION_LENGTH_ANSWERS]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.redaction_question_length_answers;
    },
    [names.NEW_MULTIPLE_CHOICE_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_multiple_choice_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_multiple_choice_question_options.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.MULTIPLE_QUESTION_SCORE_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.multiple_question_score_types;
    },
    [names.MULTIPLE_CHOICE_QUESTION_SUB_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.new_multiple_choice_question_subtype.filter(x => [1, 2].includes(x.id));
    },
    [names.NEW_FILLING_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_filling_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_FILLING_QUESTION_OPTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_filling_question_options.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_MATCHING_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_matching_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_MATCHING_QUESTION_OPTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_matching_question_options.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_MATCHING_QUESTION_SCORES]: state => {
        if (!get.store.state.user) return [];
        return state.new_matching_question_scores.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    // Sequential Question
    [names.NEW_SEQUENTIAL_QUESTIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_sequential_questions.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_FILLING_QUESTION_SUB_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.new_filling_question_sub_types;
    },
}
const mutations = {
    // New Test Segment
    [names.MUTATE_NEW_TEST_SEGMENTS]: (state, new_test_segments) => {
        new_test_segments.forEach(element => {
            let index = state.new_test_segments.findIndex(x => x.id == element.id);
            if (index != -1) state.new_test_segments.splice(index, 1, element);
            else state.new_test_segments.push(element);
        });
    },
    [names.MUTATE_NEW_TEST_SEGMENT]: (state, new_test_segment) => {
        let index = state.new_test_segments.findIndex(x => x.id == new_test_segment.id);
        if (index != -1) state.new_test_segments.splice(index, 1, new_test_segment);
        else state.new_test_segments.push(new_test_segment);
    },
    [names.MUTATE_DELETE_NEW_TEST_SEGMENT]: (state, new_test_segment_id) => {
        let index = state.new_test_segments.findIndex(x => x.id == new_test_segment_id);
        if (index != -1) state.new_test_segments.splice(index, 1);
    },
    // New Redaction Question 
    [names.MUTATE_NEW_REDACTION_QUESTIONS]: (state, new_redaction_questions) => {
        new_redaction_questions.forEach(element => {
            let index = state.new_redaction_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.new_redaction_questions.splice(index, 1, element);
            else state.new_redaction_questions.push(element);
        });
    },
    [names.MUTATE_NEW_REDACTION_QUESTION]: (state, new_redaction_question) => {
        let index = state.new_redaction_questions.findIndex(x => x.id == new_redaction_question.id);
        if (index != -1) state.new_redaction_questions.splice(index, 1, new_redaction_question);
        else state.new_redaction_questions.push(new_redaction_question);
    },
    [names.MUTATE_DELETE_NEW_REDACTION_QUESTION]: (state, new_redaction_question_id) => {
        let index = state.new_redaction_questions.findIndex(x => x.id == new_redaction_question_id);
        if (index != -1) state.new_redaction_questions.splice(index, 1);
    },
    [names.MUTATE_REDACTION_QUESTION_LENGTH_ANSWERS]: (state, redaction_question_length_answers) => {
        redaction_question_length_answers.forEach(element => {
            let index = state.redaction_question_length_answers.findIndex(x => x.id == element.id);
            if (index != -1) state.redaction_question_length_answers.splice(index, 1, element);
            else state.redaction_question_length_answers.push(element);
        });
    },
    ["MUTATE_QUESTION_BY_DELETE_TEST_SEGMENT"]: (state, new_test_segment_id) => {
        let index = state.new_redaction_questions.findIndex(x => x.segment == new_test_segment_id);
        while (index != -1) {
            state.new_redaction_questions[index].segment = null
            index = state.new_redaction_questions.findIndex(x => x.segment == new_test_segment_id);
        }
        let index2 = state.new_multiple_choice_questions.findIndex(x => x.segment == new_test_segment_id);
        while (index2 != -1) {
            state.new_multiple_choice_questions[index2].segment = null
            index2 = state.new_multiple_choice_questions.findIndex(x => x.segment == new_test_segment_id);
        }
        let index3 = state.new_filling_questions.findIndex(x => x.segment == new_test_segment_id);
        while (index3 != -1) {
            state.new_filling_questions[index3].segment = null
            index3 = state.new_filling_questions.findIndex(x => x.segment == new_test_segment_id);
        }
        let index4 = state.new_matching_questions.findIndex(x => x.segment == new_test_segment_id);
        while (index4 != -1) {
            state.new_matching_questions[index4].segment = null
            index4 = state.new_matching_questions.findIndex(x => x.segment == new_test_segment_id);
        }
        let index5 = state.new_sequential_questions.findIndex(x => x.segment == new_test_segment_id);
        while (index5 != -1) {
            state.new_sequential_questions[index5].segment = null
            index5 = state.new_sequential_questions.findIndex(x => x.segment == new_test_segment_id);
        }
    },
    // New Multiple Choice Question 
    [names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTIONS]: (state, new_multiple_choice_questions) => {
        new_multiple_choice_questions.forEach(element => {
            let index = state.new_multiple_choice_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.new_multiple_choice_questions.splice(index, 1, element);
            else state.new_multiple_choice_questions.push(element);
        });
    },
    [names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION]: (state, new_multiple_choice_question) => {
        let index = state.new_multiple_choice_questions.findIndex(x => x.id == new_multiple_choice_question.id);
        if (index != -1) state.new_multiple_choice_questions.splice(index, 1, new_multiple_choice_question);
        else state.new_multiple_choice_questions.push(new_multiple_choice_question);
    },
    [names.MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION]: (state, new_multiple_choice_question_id) => {
        let index = state.new_multiple_choice_questions.findIndex(x => x.id == new_multiple_choice_question_id);
        if (index != -1) state.new_multiple_choice_questions.splice(index, 1);
    },
    // New Multiple Choice Question Option 
    [names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS]: (state, new_multiple_choice_question_options) => {
        new_multiple_choice_question_options.forEach(element => {
            let index = state.new_multiple_choice_question_options.findIndex(x => x.id == element.id);
            if (index != -1) state.new_multiple_choice_question_options.splice(index, 1, element);
            else state.new_multiple_choice_question_options.push(element);
        });
    },
    [names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: (state, new_multiple_choice_question_option) => {
        let index = state.new_multiple_choice_question_options.findIndex(x => x.id == new_multiple_choice_question_option.id);
        if (index != -1) state.new_multiple_choice_question_options.splice(index, 1, new_multiple_choice_question_option);
        else state.new_multiple_choice_question_options.push(new_multiple_choice_question_option);
    },
    [names.MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: (state, new_multiple_choice_question_option_id) => {
        let index = state.new_multiple_choice_question_options.findIndex(x => x.id == new_multiple_choice_question_option_id);
        if (index != -1) state.new_multiple_choice_question_options.splice(index, 1);
    },
    // New Sequential Question 
    [names.MUTATE_NEW_SEQUENTIAL_QUESTIONS]: (state, new_sequential_questions) => {
        new_sequential_questions.forEach(element => {
            let index = state.new_sequential_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.new_sequential_questions.splice(index, 1, element);
            else state.new_sequential_questions.push(element);
        });
    },
    [names.MUTATE_NEW_SEQUENTIAL_QUESTION]: (state, new_sequential_question) => {
        let index = state.new_sequential_questions.findIndex(x => x.id == new_sequential_question.id);
        if (index != -1) state.new_sequential_questions.splice(index, 1, new_sequential_question);
        else state.new_sequential_questions.push(new_sequential_question);
    },
    [names.MUTATE_DELETE_NEW_SEQUENTIAL_QUESTION]: (state, new_sequential_question_id) => {
        let index = state.new_sequential_questions.findIndex(x => x.id == new_sequential_question_id);
        if (index != -1) state.new_sequential_questions.splice(index, 1);
    },
    // Multiple Question Score Types
    [names.MUTATE_MULTIPLE_QUESTION_SCORE_TYPES]: (state, multiple_question_score_types) => {
        multiple_question_score_types.forEach(element => {
            let index = state.multiple_question_score_types.findIndex(x => x.id == element.id);
            if (index != -1) state.multiple_question_score_types.splice(index, 1, element);
            else state.multiple_question_score_types.push(element);
        });
    },
    // Multiple Question Sub Types
    [names.MUTATE_MULTIPLE_CHOICE_QUESTION_SUB_TYPES]: (state, new_multiple_choice_question_subtype) => {
        new_multiple_choice_question_subtype.forEach(element => {
            let index = state.new_multiple_choice_question_subtype.findIndex(x => x.id == element.id);
            if (index != -1) state.new_multiple_choice_question_subtype.splice(index, 1, element);
            else state.new_multiple_choice_question_subtype.push(element);
        });
    },
    // New Filling Question 
    [names.MUTATE_NEW_FILLING_QUESTIONS]: (state, new_filling_questions) => {
        new_filling_questions.forEach(element => {
            let index = state.new_filling_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.new_filling_questions.splice(index, 1, element);
            else state.new_filling_questions.push(element);
        });
    },
    [names.MUTATE_NEW_FILLING_QUESTION]: (state, new_filling_question) => {
        let index = state.new_filling_questions.findIndex(x => x.id == new_filling_question.id);
        if (index != -1) state.new_filling_questions.splice(index, 1, new_filling_question);
        else state.new_filling_questions.push(new_filling_question);
    },
    [names.MUTATE_DELETE_NEW_FILLING_QUESTION]: (state, new_filling_question_id) => {
        let index = state.new_filling_questions.findIndex(x => x.id == new_filling_question_id);
        if (index != -1) state.new_filling_questions.splice(index, 1);
    },
    // New Filling Question Option 
    [names.MUTATE_NEW_FILLING_QUESTION_OPTIONS]: (state, new_filling_question_options) => {
        new_filling_question_options.forEach(element => {
            let index = state.new_filling_question_options.findIndex(x => x.id == element.id);
            if (index != -1) state.new_filling_question_options.splice(index, 1, element);
            else state.new_filling_question_options.push(element);
        });
    },
    [names.MUTATE_NEW_FILLING_QUESTION_OPTION]: (state, new_filling_question_option) => {
        let index = state.new_filling_question_options.findIndex(x => x.id == new_filling_question_option.id);
        if (index != -1) state.new_filling_question_options.splice(index, 1, new_filling_question_option);
        else state.new_filling_question_options.push(new_filling_question_option);
    },
    [names.MUTATE_DELETE_NEW_FILLING_QUESTION_OPTION]: (state, new_filling_question_option_id) => {
        let index = state.new_filling_question_options.findIndex(x => x.id == new_filling_question_option_id);
        if (index != -1) state.new_filling_question_options.splice(index, 1);
    },
    // New Matching Question 
    [names.MUTATE_NEW_MATCHING_QUESTIONS]: (state, new_matching_questions) => {
        new_matching_questions.forEach(element => {
            let index = state.new_matching_questions.findIndex(x => x.id == element.id);
            if (index != -1) state.new_matching_questions.splice(index, 1, element);
            else state.new_matching_questions.push(element);
        });
    },
    [names.MUTATE_NEW_MATCHING_QUESTION]: (state, new_matching_question) => {
        let index = state.new_matching_questions.findIndex(x => x.id == new_matching_question.id);
        if (index != -1) state.new_matching_questions.splice(index, 1, new_matching_question);
        else state.new_matching_questions.push(new_matching_question);
    },
    [names.MUTATE_DELETE_NEW_MATCHING_QUESTION]: (state, new_matching_question_id) => {
        let index = state.new_matching_questions.findIndex(x => x.id == new_matching_question_id);
        if (index != -1) state.new_matching_questions.splice(index, 1);
    },
    // New Matching Question Option 
    [names.MUTATE_NEW_MATCHING_QUESTION_OPTIONS]: (state, new_matching_question_options) => {
        new_matching_question_options.forEach(element => {
            let index = state.new_matching_question_options.findIndex(x => x.id == element.id);
            if (index != -1) state.new_matching_question_options.splice(index, 1, element);
            else state.new_matching_question_options.push(element);
        });
    },
    [names.MUTATE_NEW_MATCHING_QUESTION_OPTION]: (state, new_matching_question_option) => {
        let index = state.new_matching_question_options.findIndex(x => x.id == new_matching_question_option.id);
        if (index != -1) state.new_matching_question_options.splice(index, 1, new_matching_question_option);
        else state.new_matching_question_options.push(new_matching_question_option);
    },
    [names.MUTATE_DELETE_NEW_MATCHING_QUESTION_OPTION]: (state, new_matching_question_option_id) => {
        let index = state.new_matching_question_options.findIndex(x => x.id == new_matching_question_option_id);
        if (index != -1) state.new_matching_question_options.splice(index, 1);
    },
    // New Matching Question Score 
    [names.MUTATE_NEW_MATCHING_QUESTION_SCORES]: (state, new_matching_question_scores) => {
        new_matching_question_scores.forEach(element => {
            let index = state.new_matching_question_scores.findIndex(x => x.id == element.id);
            if (index != -1) state.new_matching_question_scores.splice(index, 1, element);
            else state.new_matching_question_scores.push(element);
        });
    },
    [names.MUTATE_NEW_MATCHING_QUESTION_SCORE]: (state, new_matching_question_score) => {
        let index = state.new_matching_question_scores.findIndex(x => x.id == new_matching_question_score.id);
        if (index != -1) state.new_matching_question_scores.splice(index, 1, new_matching_question_score);
        else state.new_matching_question_scores.push(new_matching_question_score);
    },
    [names.MUTATE_DELETE_NEW_MATCHING_QUESTION_SCORE]: (state, new_matching_question_score_id) => {
        let index = state.new_matching_question_scores.findIndex(x => x.id == new_matching_question_score_id);
        if (index != -1) state.new_matching_question_scores.splice(index, 1);
    },
    // Filling Question Sub Type
    [names.MUTATE_NEW_FILLING_QUESTION_SUB_TYPES]: (state, new_filling_question_sub_types) => {
        new_filling_question_sub_types.forEach(element => {
            let index = state.new_filling_question_sub_types.findIndex(x => x.id == element.id);
            if (index != -1) state.new_filling_question_sub_types.splice(index, 1, element);
            else state.new_filling_question_sub_types.push(element);
        });
    },
}

const actions = {
    // New Test Segment
    [names.FETCH_NEW_TEST_SEGMENT]: ({
        commit,
        state
    }, new_test_segment_id) => {
        return new Promise((resolve) => {
            const instance = state.new_test_segments.find(x => x.id == new_test_segment_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_test_segments_url}/${new_test_segment_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_SEGMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_TEST_SEGMENTS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_test_segments_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_SEGMENTS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_TEST_SEGMENT]: ({
        commit,
        state
    }, new_test_segment) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_test_segments_url}/`, new_test_segment)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_SEGMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_TEST_SEGMENT]: ({
        commit,
        state
    }, new_test_segment) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_test_segments_url}/${new_test_segment.id}/`, new_test_segment)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_SEGMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_TEST_SEGMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_test_segments_url}/${payload.new_test_segment_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_SEGMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_TEST_SEGMENT]: ({
        commit,
        state
    }, new_test_segment_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_test_segments_url}/${new_test_segment_id}/`)
                .then(response => {
                    commit("MUTATE_QUESTION_BY_DELETE_TEST_SEGMENT", new_test_segment_id);
                    commit(names.MUTATE_DELETE_NEW_TEST_SEGMENT, new_test_segment_id);
                    resolve(response);
                });
        });
    },
    // New Redaction Questions
    [names.FETCH_NEW_REDACTION_QUESTION]: ({
        commit,
        state
    }, new_redaction_question_id) => {
        return new Promise((resolve) => {
            const instance = state.new_redaction_questions.find(x => x.id == new_redaction_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_redaction_questions_url}/${new_redaction_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_REDACTION_QUESTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_redaction_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_REDACTION_QUESTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_REDACTION_QUESTION]: ({
        commit,
        state
    }, new_redaction_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_redaction_questions_url}/`, new_redaction_question)
                .then(response => {
                    commit(names.MUTATE_NEW_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_REDACTION_QUESTION]: ({
        commit,
        state
    }, new_redaction_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_redaction_questions_url}/${new_redaction_question.id}/`, new_redaction_question)
                .then(response => {
                    commit(names.MUTATE_NEW_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_REDACTION_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_redaction_questions_url}/${payload.new_redaction_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_REDACTION_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_REDACTION_QUESTION]: ({
        commit,
        state
    }, new_redaction_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_redaction_questions_url}/${new_redaction_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_REDACTION_QUESTION, new_redaction_question_id);
                    resolve(response);
                });
        });
    },
    // New redactionQuestionMaxAnswerLength
    [names.FETCH_REDACTION_QUESTION_LENGTH_ANSWERS]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.redaction_question_length_answers.length > 0) {
                resolve(state.redaction_question_length_answers);
                return;
            }
            restful.Get(`${state.redaction_question_length_answers_url}/`)
                .then(response => {
                    commit(names.MUTATE_REDACTION_QUESTION_LENGTH_ANSWERS, response);
                    resolve(response);
                });
        });
    },
    // New Multiple choice Question
    [names.FETCH_NEW_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        dispatch,
        state
    }, new_multiple_choice_question_id) => {
        return new Promise((resolve) => {
            const instance = state.new_multiple_choice_questions.find(x => x.id == new_multiple_choice_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_multiple_choice_questions_url}/${new_multiple_choice_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION, response);
                    dispatch(names.FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS, { multiple_choice_question_id: response.id, });
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_MULTIPLE_CHOICE_QUESTIONS]: ({
        commit,
        dispatch,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_multiple_choice_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTIONS, response.results);
                    if (response.results.length > 0)
                        dispatch(names.FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS, { multiple_choice_questions_ids: response.results.map((x) => x.id), });
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, new_multiple_choice_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_multiple_choice_questions_url}/`, new_multiple_choice_question)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        dispatch,
        state
    }, new_multiple_choice_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_multiple_choice_questions_url}/${new_multiple_choice_question.id}/`, new_multiple_choice_question)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION, response);
                    if (response.subtype == 1 && get.store.state.institution.internal_use_id == "duoc_uc") {
                        const option = state.new_multiple_choice_question_options.find(x => x.multiple_choice_question == response.id && x.score > 0 && x.score != response.max_score)
                        if (option)
                            dispatch(names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, { new_multiple_choice_question_option_id: option.id, item: { score: response.max_score } });
                    }
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        dispatch,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_multiple_choice_questions_url}/${payload.new_multiple_choice_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION, response);
                    if (response.subtype == 1 && get.store.state.institution.internal_use_id == "duoc_uc") {
                        const option = state.new_multiple_choice_question_options.find(x => x.multiple_choice_question == response.id && x.score > 0 && x.score != response.max_score)
                        if (option)
                            dispatch(names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, { new_multiple_choice_question_option_id: option.id, item: { score: response.max_score } });
                    }
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_MULTIPLE_CHOICE_QUESTION]: ({
        commit,
        state
    }, new_multiple_choice_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_multiple_choice_questions_url}/${new_multiple_choice_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION, new_multiple_choice_question_id);
                    resolve(response);
                });
        });
    },
    // New Multiple Choice Question Option
    [names.FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, new_multiple_choice_question_option_id) => {
        return new Promise((resolve) => {
            const instance = state.new_multiple_choice_question_options.find(x => x.id == new_multiple_choice_question_option_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_multiple_choice_question_options_url}/${new_multiple_choice_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.multiple_choice_question_id = 1
            // Estructura del payload.multiple_choice_questions_ids = [1,2,3]
            if (payload) {
                if (payload.multiple_choice_question_id) url_append += '&multiple_choice_question__id=' + payload.multiple_choice_question_id
                else if (payload.multiple_choice_questions_ids) {
                    if (payload.multiple_choice_questions_ids.length > 0)
                        url_append += '&multiple_choice_question__id__in=' + payload.multiple_choice_questions_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_multiple_choice_question_options_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, new_multiple_choice_question_option) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_multiple_choice_question_options_url}/`, new_multiple_choice_question_option)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, new_multiple_choice_question_option) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_multiple_choice_question_options_url}/${new_multiple_choice_question_option.id}/`, new_multiple_choice_question_option)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_multiple_choice_question_options_url}/${payload.new_multiple_choice_question_option_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION]: ({
        commit,
        state
    }, new_multiple_choice_question_option_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_multiple_choice_question_options_url}/${new_multiple_choice_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_MULTIPLE_CHOICE_QUESTION_OPTION, new_multiple_choice_question_option_id);
                    resolve(response);
                });
        });
    },
    // New MultipleQuestionScoreType
    [names.FETCH_MULTIPLE_QUESTION_SCORE_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.multiple_question_score_types.length > 0) {
                resolve(state.multiple_question_score_types);
                return;
            }
            restful.Get(`${state.multiple_question_score_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_QUESTION_SCORE_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New multipleChoiceQuestionSubtype
    [names.FETCH_MULTIPLE_CHOICE_QUESTION_SUB_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.new_multiple_choice_question_subtype.length > 0) {
                resolve(state.new_multiple_choice_question_subtype);
                return;
            }
            restful.Get(`${state.new_multiple_choice_question_subtype_url}/`)
                .then(response => {
                    commit(names.MUTATE_MULTIPLE_CHOICE_QUESTION_SUB_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New Filling Question
    [names.FETCH_NEW_FILLING_QUESTION]: ({
        commit,
        dispatch,
        state,
    }, new_filling_question_id) => {
        return new Promise((resolve) => {
            const instance = state.new_filling_questions.find(x => x.id == new_filling_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_filling_questions_url}/${new_filling_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION, response);
                    dispatch(names.FETCH_NEW_FILLING_QUESTION_OPTIONS, { filling_question_id: response.id, });
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_FILLING_QUESTIONS]: ({
        commit,
        dispatch,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_filling_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTIONS, response.results);
                    if (response.results.length > 0)
                        dispatch(names.FETCH_NEW_FILLING_QUESTION_OPTIONS, { filling_questions_ids: response.results.map((x) => x.id), });
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_FILLING_QUESTION]: ({
        commit,
        state
    }, new_filling_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_filling_questions_url}/`, new_filling_question)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_FILLING_QUESTION]: ({
        commit,
        state
    }, new_filling_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_filling_questions_url}/${new_filling_question.id}/`, new_filling_question)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_FILLING_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_filling_questions_url}/${payload.new_filling_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_FILLING_QUESTION]: ({
        commit,
        state
    }, new_filling_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_filling_questions_url}/${new_filling_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_FILLING_QUESTION, new_filling_question_id);
                    resolve(response);
                });
        });
    },
    // New Filling Question Option
    [names.FETCH_NEW_FILLING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_filling_question_option_id) => {
        return new Promise((resolve) => {
            const instance = state.new_filling_question_options.find(x => x.id == new_filling_question_option_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_filling_question_options_url}/${new_filling_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_FILLING_QUESTION_OPTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.filling_question_id = 1
            // Estructura del payload.filling_questions_ids = [1,2,3]
            if (payload) {
                if (payload.filling_question_id) url_append += '&filling_question__id=' + payload.filling_question_id
                else if (payload.filling_questions_ids) {
                    if (payload.filling_questions_ids.length > 0)
                        url_append += '&filling_question__id__in=' + payload.filling_questions_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_filling_question_options_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION_OPTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_FILLING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_filling_question_option) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_filling_question_options_url}/`, new_filling_question_option)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_FILLING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_filling_question_option) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_filling_question_options_url}/${new_filling_question_option.id}/`, new_filling_question_option)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_FILLING_QUESTION_OPTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_filling_question_options_url}/${payload.new_filling_question_option_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_FILLING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_filling_question_option_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_filling_question_options_url}/${new_filling_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_FILLING_QUESTION_OPTION, new_filling_question_option_id);
                    resolve(response);
                });
        });
    },
    // New Matching Question
    [names.FETCH_NEW_MATCHING_QUESTION]: ({
        commit,
        dispatch,
        state
    }, new_matching_question_id) => {
        return new Promise((resolve) => {
            const instance = state.new_matching_questions.find(x => x.id == new_matching_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_matching_questions_url}/${new_matching_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION, response);
                    dispatch(names.FETCH_NEW_MATCHING_QUESTION_OPTIONS, { matching_questions_id: response.id, });
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_MATCHING_QUESTIONS]: ({
        commit,
        state,
        dispatch,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_matching_questions_url}/${url_append}`)
                .then(response => {
                    if (response.results.length > 0) {
                        commit(names.MUTATE_NEW_MATCHING_QUESTIONS, response.results);
                        dispatch(names.FETCH_NEW_MATCHING_QUESTION_OPTIONS, { matching_questions_ids: response.results.map((x) => x.id), });
                        dispatch(names.FETCH_NEW_MATCHING_QUESTION_SCORES, { matching_questions_ids: response.results.map((x) => x.id), });
                    }
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_MATCHING_QUESTION]: ({
        commit,
        state
    }, new_matching_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_matching_questions_url}/`, new_matching_question)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_MATCHING_QUESTION]: ({
        commit,
        state
    }, new_matching_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_matching_questions_url}/${new_matching_question.id}/`, new_matching_question)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_MATCHING_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_matching_questions_url}/${payload.new_matching_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_MATCHING_QUESTION]: ({
        commit,
        state
    }, new_matching_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_matching_questions_url}/${new_matching_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_MATCHING_QUESTION, new_matching_question_id);
                    resolve(response);
                });
        });
    },
    // New Matching Question Option
    [names.FETCH_NEW_MATCHING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_matching_question_option_id) => {
        return new Promise((resolve) => {
            const instance = state.new_matching_question_options.find(x => x.id == new_matching_question_option_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_matching_question_options_url}/${new_matching_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_MATCHING_QUESTION_OPTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.matching_question_id = 1
            // Estructura del payload.matching_questions_ids = [1,2,3]
            if (payload) {
                if (payload.matching_question_id) url_append += '&matching_question__id=' + payload.matching_question_id
                else if (payload.matching_questions_ids) {
                    if (payload.matching_questions_ids.length > 0)
                        url_append += '&matching_question__id__in=' + payload.matching_questions_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_matching_question_options_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_OPTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_MATCHING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_matching_question_option) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_matching_question_options_url}/`, new_matching_question_option)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_MATCHING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_matching_question_option) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_matching_question_options_url}/${new_matching_question_option.id}/`, new_matching_question_option)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_MATCHING_QUESTION_OPTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_matching_question_options_url}/${payload.new_matching_question_option_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_OPTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_MATCHING_QUESTION_OPTION]: ({
        commit,
        state
    }, new_matching_question_option_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_matching_question_options_url}/${new_matching_question_option_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_MATCHING_QUESTION_OPTION, new_matching_question_option_id);
                    resolve(response);
                });
        });
    },
    // New Matching Question Score
    [names.FETCH_NEW_MATCHING_QUESTION_SCORE]: ({
        commit,
        state
    }, new_matching_question_score_id) => {
        return new Promise((resolve) => {
            const instance = state.new_matching_question_scores.find(x => x.id == new_matching_question_score_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_matching_question_scores_url}/${new_matching_question_score_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_MATCHING_QUESTION_SCORES]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.matching_question_id = 1
            // Estructura del payload.matching_questions_ids = [1,2,3]
            if (payload) {
                if (payload.matching_question_id) url_append += '&matching_question_unit_1__matching_question__id=' + payload.matching_question_id
                else if (payload.matching_questions_ids) {
                    if (payload.matching_questions_ids.length > 0)
                        url_append += '&matching_question_unit_1__matching_question__id__in=' + payload.matching_questions_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_matching_question_scores_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_SCORES, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.FETCH_NEW_MATCHING_QUESTION_SCORES_BY]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve,) => {
            //matching_question_unit_1 => display_to_the_left == true
            //matching_question_unit_2 => display_to_the_left == false
            const instance = state.new_matching_question_scores.find(
                (x) => x.matching_question_unit_1 == payload.matching_question_unit_1 &&
                    x.matching_question_unit_2 == payload.matching_question_unit_2);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_matching_question_scores_url}/?all_data=True&matching_question_unit_1__id=${payload.matching_question_unit_1}&matching_question_unit_2__id=${payload.matching_question_unit_2}`)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_SCORES, response.results);
                    if (response.results.length > 0) {
                        resolve(response.results[0]);
                    } else resolve(null)
                });
        });
    },
    [names.POST_NEW_MATCHING_QUESTION_SCORE]: ({
        commit,
        state
    }, new_matching_question_score) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_matching_question_scores_url}/`, new_matching_question_score)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_MATCHING_QUESTION_SCORE]: ({
        commit,
        state
    }, new_matching_question_score) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_matching_question_scores_url}/${new_matching_question_score.id}/`, new_matching_question_score)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_MATCHING_QUESTION_SCORE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_matching_question_scores_url}/${payload.new_matching_question_score_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_MATCHING_QUESTION_SCORE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_MATCHING_QUESTION_SCORE]: ({
        commit,
        state
    }, new_matching_question_score_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_matching_question_scores_url}/${new_matching_question_score_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_MATCHING_QUESTION_SCORE, new_matching_question_score_id);
                    resolve(response);
                });
        });
    },
    // New Filling Question Sub Types
    [names.FETCH_NEW_FILLING_QUESTION_SUB_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.new_filling_question_sub_types.length > 0) {
                resolve(state.new_filling_question_sub_types);
                return;
            }
            restful.Get(`${state.new_filling_question_sub_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_FILLING_QUESTION_SUB_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New Sequential Question
    [names.FETCH_NEW_SEQUENTIAL_QUESTION]: ({
        commit,
        state
    }, new_sequential_question_id) => {
        return new Promise((resolve) => {
            const instance = state.new_sequential_questions.find(x => x.id == new_sequential_question_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_sequential_questions_url}/${new_sequential_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_SEQUENTIAL_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_SEQUENTIAL_QUESTIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_sequential_questions_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_SEQUENTIAL_QUESTIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_SEQUENTIAL_QUESTION]: ({
        commit,
        state
    }, new_sequential_question) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_sequential_questions_url}/`, new_sequential_question)
                .then(response => {
                    commit(names.MUTATE_NEW_SEQUENTIAL_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_SEQUENTIAL_QUESTION]: ({
        commit,
        state
    }, new_sequential_question) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_sequential_questions_url}/${new_sequential_question.id}/`, new_sequential_question)
                .then(response => {
                    commit(names.MUTATE_NEW_SEQUENTIAL_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_SEQUENTIAL_QUESTION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_sequential_questions_url}/${payload.new_sequential_question_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_SEQUENTIAL_QUESTION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_SEQUENTIAL_QUESTION]: ({
        commit,
        state
    }, new_sequential_question_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_sequential_questions_url}/${new_sequential_question_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_SEQUENTIAL_QUESTION, new_sequential_question_id);
                    resolve(response);
                });
        });
    },
}
export default {
    state,
    getters,
    mutations,
    actions,
};