import restful from "@/utils/restful";
import * as names from "@/store/names";
import * as get from "@/store/store";
import { buildUrlParams } from '@/utils/utils';

const state = {
    competence_units: [],
    competence_units_url: "/teaching/temp-competence-unit",
};

const getters = {
    [names.COMPETENCE_UNITS]: (state) => {
        if (!get.store.state.user) return [];
        return state.competence_units
            .filter((x) => x.school == get.store.state.user.school)
            .sort((a, b) => a.order - b.order);
    },
};

const mutations = {
    [names.MUTATE_COMPETENCE_UNITS]: (state, competence_units) => {
        competence_units.forEach((element) => {
            let index = state.competence_units.findIndex((x) => x.id == element.id);
            if (index != -1) state.competence_units.splice(index, 1, element);
            else state.competence_units.push(element);
        });
    },
    [names.MUTATE_STUDY_COMPETENCE]: (state, study_competence) => {
        let index = state.competence_units.findIndex((x) => x.id == study_competence.id);
        if (index != -1) state.competence_units.splice(index, 1, study_competence);
        else state.competence_units.push(study_competence);
    },
    [names.MUTATE_DELETE_STUDY_COMPETENCE]: (state, study_competence_id) => {
        let index = state.competence_units.findIndex((x) => x.id == study_competence_id);
        if (index != -1) state.competence_units.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_STUDY_COMPETENCE]: ({ commit, state }, study_competence_id) => {
        return new Promise((resolve) => {
            restful
                .Get(`${state.competence_units_url}/${study_competence_id}/`)
                .then((response) => {
                    commit(names.MUTATE_STUDY_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_COMPETENCE_UNITS]: ({ commit, state }, payload) => {
        return new Promise((resolve) => {
            if (payload && payload.matter__id && state.competence_units.filter(x => x.matter == payload.matter__id).length > 0) {
                resolve(state.competence_units);
                return;
            }
            // filterset_fields
            //  'id': ['exact', 'in'],
            // 'matter__id': ['exact', 'in'],
            // 'competence__id': ['exact', 'in'],
            //  'matter__egress_profile_matters__egress_profile__id': ['exact', 'in'],
            const urlParams = buildUrlParams(payload);
            if (!urlParams) {
                resolve([]);
                return;
            }
            restful.Get(`${state.competence_units_url}/?${urlParams}`).then((response) => {
                commit(names.MUTATE_COMPETENCE_UNITS, response.results);
                resolve(response.results);
            });
        });
    },
    [names.POST_STUDY_COMPETENCE]: ({ commit, state }, study_competence) => {
        return new Promise((resolve) => {
            restful
                .Post(`${state.competence_units_url}/`, study_competence)
                .then((response) => {
                    commit(names.MUTATE_STUDY_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_STUDY_COMPETENCE]: ({ commit, state }, study_competence) => {
        return new Promise((resolve) => {
            restful
                .Put(`${state.competence_units_url}/${study_competence.id}/`, study_competence)
                .then((response) => {
                    commit(names.MUTATE_STUDY_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_STUDY_COMPETENCE]: ({ commit, state }, payload) => {
        return new Promise((resolve) => {
            restful
                .Patch(
                    `${state.competence_units_url}/${payload.study_competence_id}/`,
                    payload.item
                )
                .then((response) => {
                    commit(names.MUTATE_STUDY_COMPETENCE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_STUDY_COMPETENCE]: ({ commit, state }, study_competence_id) => {
        return new Promise((resolve) => {
            restful
                .Delete(`${state.competence_units_url}/${study_competence_id}/`)
                .then((response) => {
                    commit(names.MUTATE_DELETE_STUDY_COMPETENCE, study_competence_id);
                    commit("MUTATE_STUDY_UNITS_BY_DELETE_STUDY_COMPETENCE", study_competence_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
