import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import public_routes from './router/public';
import { store } from './store/store';
import { APP_NAME } from './utils/globals';
import * as names from "@/store/names";

import { BootstrapVue, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
Vue.use(BootstrapVueIcons);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

let moment = require('moment-timezone');
moment.tz.setDefault('America/Santiago');
require('moment/locale/es')
Vue.use(require('vue-moment'), {
    moment
});

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import ButtonAdd from '@/components/reusable/ButtonAdd';
Vue.component("button-add", ButtonAdd);

import ButtonEdit from '@/components/reusable/ButtonEdit';
Vue.component("button-edit", ButtonEdit);

import ButtonDelete from '@/components/reusable/ButtonDelete';
Vue.component("button-delete", ButtonDelete);

import Footer from '@/components/public/Footer.vue';
Vue.component('footer-component', Footer);

import Toolbar from '@/components/public/Toolbar.vue';
Vue.component('toolbar-component', Toolbar);

import VueGlobalVar from 'vue-global-var';
import restful from '@/utils/restful';

//  filtered sirve para saber si en A contiene algo de B 
// (Puede tener más de un resultado y funciona bien para cuando se filtra)
export const filtered = (A, B) => {
    if (String(A).normalize("NFD")
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
        .normalize()
        .trim()
        .toLowerCase()
        .includes(String(B).normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase())) return true
    else return false
};

// equals sirve para saber si A = B
export const equals = (A, B) => {
    if (String(A).normalize("NFD")
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
        .normalize()
        .trim()
        .toLowerCase() ==
        String(B).normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase()) return true
    else return false
};

export const getVisibleNames = (table_name, isPlural, original_name) => {
    if (!store.state.user) return original_name;
    if (!store.state.user.school) return original_name;
    const alternative_name = store.state.alternative_names.alternative_names.find(
        (x) => x.table_name == table_name && x.school == store.state.user.school
    );
    if (!alternative_name) return original_name;
    if (isPlural)
        return alternative_name ?
            alternative_name.visible_name_plural ?
                alternative_name.visible_name_plural :
                alternative_name.verbose_name_plural :
            original_name;
    else
        return alternative_name ?
            alternative_name.visible_name ?
                alternative_name.visible_name :
                alternative_name.verbose_name :
            original_name;
};

export const hasObjectPermission = (table_name, methods, id = null) => {
    const table = store.state.positions.user_table_access.find(x => equals(x.table_name, table_name))
    if (table) {
        if (equals(methods, 'add') && table.can_create) return true
        if (equals(methods, 'change') && (equals(table.can_update, 'all') || table.can_update.split(",").map(Number).includes(id))) return true
        if (equals(methods, 'delete') && (equals(table.can_delete, 'all') || table.can_delete.split(",").map(Number).includes(id))) return true
        if (equals(methods, 'special') && (equals(table.can_do_special_behavior, 'all') || table.can_do_special_behavior.split(",").map(Number).includes(id))) return true
    } else return false
    return false
}

export const hasPermissions = (permission = null) => {
    if (!store.getters.isLoged && router.currentRoute.name != "Welcome") {
        store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        router.push({ name: "Welcome" });
        return false
    }
    else {
        if (!permission) {
            store.commit(names.MUTATE_VISIBILITY_CRUD, false);
            return false
        } else {
            try {
                const permissions = JSON.parse(localStorage.getItem('permissions'));
                const expressions = permission.split(' ');
                const allows_crud = store.state.utils.allows_crud;
                const findExpession = expressions.find(expression => permissions.includes(expression.replace(/'/g, "").replace(/"/g, "")));
                if (findExpession) store.commit(names.MUTATE_VISIBILITY_CRUD, true);
                else store.commit(names.MUTATE_VISIBILITY_CRUD, false);
                if (allows_crud && findExpession) {
                    return allows_crud;
                }
                else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }
    }
}

export const lightOrDark = (color) => {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
            "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return true; // return true if the colour is brighter
    } else {
        return false; // return false if the colour is darker
    }
}

export const htmlToPlainText = (html) => {
    // Crea un nuevo DOMParser
    const parser = new DOMParser();
    // Parsea el HTML a un nuevo documento
    const doc = parser.parseFromString(html, 'text/html');
    // Retorna el texto del documento, que será el texto plano
    return doc.body.textContent || "";
}

export const logOut = () => {
    if (!public_routes.map(x => x.name).includes(router.currentRoute.name) || router.currentRoute.name == 'Page404') {
        router.push({ name: "Login" });
    }
    store.commit('clearLoginData');
}

// Esta variable se ocupará para poder probar como se vería en la institución Duoc y hacer pruebas locales.
export const debug_change_duoc = false;
// Esta variable se ocupará para poder probar como se vería en la institución Unab y hacer pruebas locales.
export const debug_change_unab = false;
// Esta variable se ocupará para poder probar como se vería en la institución MANUELA BELTRÁN y hacer pruebas locales.
export const debug_change_umb = false;
//Variable para controlar si se quiere colocar en mantenimiento las instituciones…
export const maintenance_view = false;

Vue.use(VueGlobalVar, {
    globals: {
        $restful: restful,
        $getVisibleNames: getVisibleNames,
        $filtered: filtered,
        $equals: equals,
        $hasPermissions: hasPermissions,
        $hasObjectPermission: hasObjectPermission,
        $lightOrDark: lightOrDark,
        $htmlToPlainText: htmlToPlainText,
        $debug_change_duoc: debug_change_duoc,
        $debug_change_unab: debug_change_unab,
        $debug_change_umb: debug_change_umb,
        $maintenance_view: maintenance_view,
        $logOut: logOut,
    }
});

Vue.config.productionTip = false;


router.beforeEach((to, from, next) => {
    if (from.name == 'Welcome' && store.getters.isLoged && store.getters.getUser && !store.getters.getUser.has_changed_password) {
        return
    }
    else if (from.name == 'Welcome' && store.getters.isLoged && store.getters.getInstitution && store.getters.getInstitution.id != 16 && maintenance_view)
        return
    if (to.name == 'Login' && store.getters.isLoged) {
        router.replace({ name: 'Welcome' });
        // next();
        return
    }
    next();
    // router.replace('/login', () => {
    //   next(from);
    // })
    // }
});

Vue.directive('can', {
    bind: function (el, binding, vnode) {
        try {
            const expressions = binding.expression.split(' ');
            const permissions = JSON.parse(localStorage.getItem('permissions'));
            expressions.forEach(expression => {
                if (!permissions.includes(expression.replace(/'/g, "").replace(/"/g, ""))) {
                    vnode.elm.style.display = "none";
                    return;
                }
            });
        } catch (error) {
            console.log('Directive error: ', error);
            vnode.elm.style.display = "none";
        }
    }
});

Vue.filter('toDateTime', function (date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD HH:mm");
});

Vue.filter('toDate', function (date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
});

Vue.filter('Round', function (number) {
    return +(Math.round(Number(number) + "e+2") + "e-2");
});

Vue.filter('FormatToDate', function (date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY");
});

Vue.filter('FormatToTime', function (date) {
    if (!date) return "";
    return moment(date).format("HH:mm");
});

Vue.filter('FormatToYear', function (date) {
    if (!date) return "";
    return moment(date).format("YYYY");
});

Vue.filter('FormatToDateTime', function (date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY HH:mm");
});
Vue.filter('FormatToTimeDate', function (date) {
    if (!date) return "";
    return moment(date).format("HH:mm DD/MM/YYYY");
});

Vue.filter('FormatToFullDateEsp', function (date) {
    if (!date) return "";
    return moment(date).format('dddd, D [de] MMMM [de] YYYY');
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    mounted: function () {
        const logo_gera = require("@/assets/logo_gera.png");
        const logo_kl = require("@/assets/logo_klearning.png");
        Vue.prototype.$logo = APP_NAME == 'GERA' ? logo_gera : logo_kl;
    }
});
