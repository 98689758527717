
export default [
    {
        path: '/reports/form/',
        name: 'ReportsView',
        component: () =>
            import ("@/components/reports/ReportsView"),
    },
    {
        path: '/reports/students/',
        name: 'StudentsReportsView',
        component: () =>
            import ("@/components/reports/StudentsReports"),
    },
];
