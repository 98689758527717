import Vue from 'vue';
import Vuex from 'vuex';

import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import alternative_names from "./modules/alternative_names";
import assessment_types from "./modules/new_assessments/assessment_types";
import assessments from "./modules/new_assessments/assessments";
import autonomous_teaching_activities from "./modules/autonomous_teaching_activity";
import bibliographic_resource_types from "./modules/bibliographic_resource_types";
import campuses from "./modules/campuses";
import careers from "./modules/careers";
import capacities from "./modules/capacities";
import certifications from "./modules/certifications";
import communes from "./modules/communes";
import courses_wrapper from "./modules/courses_wrapper";
import cycles from "./modules/cycles";
import competences from "./modules/competences";
import competence_types from "./modules/competence_types";
import competence_units from "./modules/competence_units";
import contents from "./modules/contents";
import countries from "./modules/countries";
import direct_teaching_activities from "./modules/direct_teaching_activities";
import egress_profiles_matters from "./modules/egress_profiles_matters";
import egress_profiles from "./modules/egress_profiles";
import egress_profile_competence from "./modules/egress_profile_competence";
import egress_profile_cycle from "./modules/egress_profile_cycle";
import egress_profile_type from "./modules/egress_profile_type";
import evaluation_feedbacks from "./modules/evaluation_feedbacks";
import evaluation_criterias from "./modules/evaluation_criterias";
import evaluation_instruments from "./modules/evaluation_instruments";
import evaluation_planning from "./modules/evaluation_planning";
import evaluations from "./modules/evaluations";
import evaluatee_assessments from "./modules/new_assessments/evaluatee_assessments";
import new_evaluations from "./modules/new_evaluation_modules/new_evaluation";
import new_questions from "./modules/new_evaluation_modules/new_questions";
import new_rubrics from "./modules/new_evaluation_modules/new_rubrics";
import new_evaluatee_score from "./modules/new_evaluation_modules/new_evaluatee_score";
import faculties from "./modules/faculties";
import files from "./modules/files";
import formation_areas from "./modules/formation_areas";
import formation_lines from "./modules/formation_lines";
import images from "./modules/images";
import institution_competences from "./modules/institution_competences";
import instructives from "./modules/instructives";
import learning_results from "./modules/learning_results";
import matters from "./modules/matters";
import matter_contributions from "./modules/matter_contributions";
import time_allocations from "./modules/time_allocations";
import matter_performance_indicators from "./modules/matter_performance_indicators";
import matter_types from "./modules/matter_types";
import methodological_strategies from "./modules/methodological_strategies";
import modalities from "./modules/modalities";
import shifts from "./modules/shifts";
import study_environments from "./modules/study_environments";
import performance_indicators from "./modules/performance_indicators";
import periods from "./modules/periods";
import positions from "./modules/positions";
import procedure_evidences from "./modules/procedure_evidences";
import ra_base from "./modules/ra_base";
import ra_macros from "./modules/ra_macros";
import ra_micros from "./modules/ra_micros";
import regimes from "./modules/regimes";
import regions from "./modules/regions";
import rubrics from "./modules/rubrics";
import sections from "./modules/sections";
import session_dates from "./modules/session_dates";
import session_plannings from "./modules/session_plannings";
import study_plan_comments from "./modules/study_plan_comments";
import study_units from "./modules/study_units";
import support_resources from "./modules/support_resources";
import support_resource_types from "./modules/support_resource_types";
import taxonomies from "./modules/taxonomies";
import users from "./modules/users";
import tests from "./modules/tests";
import question_tests from "./modules/question_tests";
import test_answers from "./modules/test_answers";
import utils from "./modules/utils";
import { isValidJwt, expiresAt } from '../utils/utils';


Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        app_name: 'KEY LEARNING',
        jwt_token: localStorage.getItem('jwt_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        expires_at: expiresAt(localStorage.getItem('jwt_token')),
        is_loged: isValidJwt(),

        user: JSON.parse(localStorage.getItem('user')),
        institution: JSON.parse(localStorage.getItem('institution')),
        permissions: JSON.parse(localStorage.getItem('permissions')),
        institution_logo: localStorage.getItem('institution_logo'),
        user_institution_id: 1,
    },
    getters,
    mutations,
    actions,
    modules: {
        alternative_names,
        assessments,
        assessment_types,
        autonomous_teaching_activities,
        bibliographic_resource_types,
        campuses,
        careers,
        capacities,
        certifications,
        communes,
        courses_wrapper,
        cycles,
        competences,
        competence_types,
        competence_units,
        contents,
        countries,
        direct_teaching_activities,
        egress_profiles_matters,
        egress_profiles,
        egress_profile_competence,
        egress_profile_cycle,
        egress_profile_type,
        evaluation_feedbacks,
        evaluation_criterias,
        evaluation_instruments,
        evaluation_planning,
        evaluations,
        evaluatee_assessments,
        new_evaluations,
        new_questions,
        new_rubrics,
        new_evaluatee_score,
        faculties,
        files,
        formation_areas,
        formation_lines,
        images,
        institution_competences,
        instructives,
        learning_results,
        matters,
        matter_contributions,
        matter_performance_indicators,
        matter_types,
        methodological_strategies,
        modalities,
        performance_indicators,
        periods,
        positions,
        procedure_evidences,
        ra_base,
        ra_macros,
        ra_micros,
        regimes,
        regions,
        rubrics,
        sections,
        session_dates,
        session_plannings,
        shifts,
        study_environments,
        study_plan_comments,
        study_units,
        support_resources,
        support_resource_types,
        taxonomies,
        users,
        time_allocations,
        tests,
        question_tests,
        test_answers,
        utils,
    }
});
